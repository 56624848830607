































































































































































































































































import PageHeader from '~/components/app/page-header.vue'
import SeriesTypeSelect from '~/components/autocomplete/series-type-select.vue'
import {CourseSeries, SeriesType} from '~/components/data-class/data-class'
import SeriesData from '~/components/series/series-data'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {Chart, PeriodStep} from '~/utils/chart'
import {createRequest} from '~/utils/network-request'
import {ReportPermission} from '~/utils/permissions'
import _ from 'lodash'
import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'
import {
    VBadge,
    VBtn,
    VChip,
    VChipGroup,
    VCol,
    VContainer,
    VDataFooter,
    VDataTable,
    VDivider,
    VIcon,
    VOverlay,
    VProgressCircular,
    VRow,
    VSimpleTable,
    VSwitch,
    VTab,
    VTabs,
    VTextField,
    VAutocomplete
} from 'vuetify/lib'

@Component({
    components: {
        PageHeader,
        VTab,
        VBtn,
        VBadge,
        VTabs,
        VChipGroup,
        VChip,
        VSwitch,
        VDivider,
        VTextField,
        VDataTable,
        VDataFooter,
        VContainer,
        VIcon,
        VRow,
        VCol,
        VSimpleTable,
        SeriesTypeSelect,
        VProgressCircular,
        VOverlay,
        VAutocomplete
    }
})
export default class SeriesIdReport extends mixins(DatePickerShortcutMixin) {
    static permission = ReportPermission.SeriesReport

    //  META DATA
    _ = _
    revenueOption: string[] = ['Volume', 'Day', 'Week', 'Month']
    SeriesType = SeriesType
    //  UI DATA
    isLoading: boolean = false
    selSeriesType: SeriesType = SeriesType.LIVE
    selDateRange: number[] = [0, 0]
    realDateRange: number[] = [0, 0]

    selRevenueOption: number = 0

    versionOptions: any[] = []
    selCoursesVersion: number = 0

    totalRevenue: number = 0

    totalRetention: string = ''

    totalAttendance: string = ''

    totalProgress: string = ''

    dateLabelList: string[] = []

    tableHeadersLive = [
        {text: 'ID', value: '_id', width: 80, sortable: false},
        // {text: 'Course', value: 'title', sortable: false},
        {text: 'Avg. retention', value: 'retention', width: 150},
        {text: 'Avg. attendance', value: 'attendance', width: 150},

        {text: 'Reserved', value: 'quota.reserved', sortable: false},
        {text: 'Applied', value: 'quota.applied', sortable: false},
        {text: 'Paid', value: 'quota.paid', sortable: false},
        {text: 'Vacancy', value: 'quota.vacancy', sortable: false},

        {text: 'AR new', value: 'auto_renewal.new', sortable: false},
        {text: 'AR keep', value: 'auto_renewal.keep', sortable: false},
        {text: 'AR cancel', value: 'auto_renewal.cancelled', sortable: false},
        {text: 'Rated', value: 'rated', sortable: false},
    ]

    tableHeadersOnline = [
        {text: 'ID', value: '_id', width: 80, sortable: false},
        {text: 'Course', value: 'title', sortable: false},
        {text: 'Avg. progress', value: 'progress', sortable: false},
    ]


    //  DATA
    seriesId: number = -1
    series: CourseSeries = new CourseSeries()

    attendances: any = {}
    courses: any[] = []
    retention: any = {}
    revenue: any[] = []
    retentionTable: any[] = []

    avgProgress: any = {}

    allVolCat: string[] = []

    natural(num: number) {
        return num > 0 ? num : '-'
    }

    get pickerOptions(): { shortcuts: Array<{ text: string, onClick: {} }> } {
        return {
            shortcuts: this.datePickerShortcuts
        }
    }

    get mergedCourseList() {
        let courseList = [...this.courses]
        if (this.selSeriesType === SeriesType.LIVE && (!courseList[0] || !courseList[0].quota))
            return [] // not fetched yet

        courseList = _.map(courseList, (item, index) => _.extend({}, item, {vol_id: index}))


        if (this.selSeriesType === SeriesType.LIVE) {
            if (!!this.retention.courses) {
                const retentionDict = this.retention.courses
                courseList = _.map(courseList, item => _.extend({}, item, {retention: retentionDict[item._id] ? retentionDict[item._id].percentage : 0}))
            }
            if (!!this.attendances.courses) {
                const attendanceDict = this.attendances.courses
                courseList = _.map(courseList, item => _.extend({}, item, {attendance: attendanceDict[item._id] ? attendanceDict[item._id].percentage : 0}))
            }
        } else {
            if (!_.isEqual(this.avgProgress, {})) {
                courseList = _.map(courseList, item => _.extend({}, item, {progress: this.avgProgress[item._id] ? this.avgProgress[item._id] : 0}))
            }
        }

        if (!!this.revenue.length) {
            const aggregated = _.reduce(this.revenue, (result, item) => {
                return {
                    ...result,
                    [item.course_id]: (result[item.course_id] || 0) + item.revenue
                }
            }, {})
            courseList = _.map(courseList, item => _.extend({}, item, {revenue: aggregated[item._id] ? aggregated[item._id] : 0}))
        }
        return courseList
    }

    get sortedRevenue() {
        return _.reverse(_.sortBy(this.mergedCourseList, 'revenue'))
    }

    get revenueConfig() {
        if (this.selRevenueOption === 0) {
            return {
                chart: {
                    type: 'bar',
                    height: 300
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: this.allVolCat
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        title: {
                            formatter: (seriesName) => '',
                        },
                        formatter: (value) => '$' + value
                    },
                }
            }
        }
        return {
            chart: {
                height: 300,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                curve: 'straight',
            },
            xaxis: {
                categories: this.dateLabelList,
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => ''
                    },
                    formatter: (value) => '$' + value
                },
            }
        }
    }

    get revenueData() {
        if (this.selRevenueOption === 0) {
            return [
                {data: _.map(this.mergedCourseList, item => item.revenue || 0)}
            ]
        } else {
            const recordsByDate = _.sortBy(this.revenue, ['paid_time'])

            let periodStep: PeriodStep = PeriodStep.DAY
            if (this.selRevenueOption === 2) {
                periodStep = PeriodStep.WEEK
            } else if (this.selRevenueOption === 3) {
                periodStep = PeriodStep.MONTH
            }

            const allDate: string[] = []
            let dataResult: {} = {}
            const allPeriodStart = Chart.generatePeriodStart(this.realDateRange[0], this.realDateRange[1], periodStep)

            for (const periodStart of allPeriodStart) {
                //  Prepare data
                const matchPeriod = _.remove(recordsByDate, item => moment(periodStart).isSame(item.quota.paid_time, Chart.transformUnit(periodStep)))
                const dailyTotal = _.reduce(matchPeriod, (result, item) => {
                    return result + item.revenue
                }, 0)

                // Prepare date label
                const dateLabel = Chart.formatPeriodLabel(periodStart, this.realDateRange[0], this.realDateRange[1], periodStep)
                allDate.push(dateLabel)

                dataResult = {
                    ...dataResult,
                    [dateLabel]: dailyTotal
                }
            }

            this.dateLabelList = allDate

            return [
                {data: _.values(dataResult)}
            ]
        }
    }

    get lineChartConfig() {
        return {
            chart: {
                height: 300,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                curve: 'straight',
            },
            xaxis: {
                categories: this.allVolCat,
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => ''
                    },
                    formatter: (value) => value + '%'
                },
            }
        }
    }

    get attendanceData() {
        if (!this.mergedCourseList) {
            this.totalAttendance = '0'
            return [{data: []}]
        }

        if (!!this.attendances.series) {
            this.totalAttendance = ((this.attendances.series.percentage || 0) * 100).toFixed(2)
        }

        return [
            {data: _.map(this.mergedCourseList, item => (item.attendance * 100).toFixed(2))}
        ]
    }

    get progressConfig() {
        return {
            chart: {
                type: 'bar',
                height: 300
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: this.allVolCat
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => '',
                    },
                    formatter: (value) => value + '%'
                },
            }
        }
    }

    get progressData() {
        if (!this.mergedCourseList) {
            this.totalProgress = '0'
            return [{data: []}]
        }

        if (!!this.avgProgress) {
            this.totalProgress = ((this.avgProgress.avg || 0) * 100).toFixed(2)

            return [{
                data: _.map(this.mergedCourseList, item => {
                    const progress = this.avgProgress[item._id] || 0
                    return (progress * 100).toFixed(2)
                })
            }]
        }
        return [{data: []}]
    }

    get retentionData() {
        if (!this.mergedCourseList) {
            return [{data: []}]
        }

        this.totalRetention = (_.sumBy(this.mergedCourseList, 'retention') / this.mergedCourseList.length * 100).toFixed(2)

        return [
            {data: _.map(this.mergedCourseList, item => (item.retention * 100).toFixed(2))}
        ]
    }

    async created() {
        this.seriesId = parseInt(this.$route.params.id)
        this.series = await SeriesData.shouldGetSeries(this.seriesId) || new CourseSeries()

        this.selSeriesType = this.series.offline ? SeriesType.LIVE : SeriesType.ONLINE

        this.selDateRange = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
        this.realDateRange = [...this.selDateRange]
        await this.fetchSeriesDashboard()
    }

    mounted() {
        window.scrollTo(0, 0)
    }


    fetchSeriesDashboard() {
        const start = moment(this.selDateRange[0]).valueOf()
        const end = moment(this.selDateRange[1]).valueOf()

        switch (this.selSeriesType) {
            case SeriesType.LIVE:
                return this.fetchSeriesDashboardOffline(start, end)
            case SeriesType.ONLINE:
                return this.fetchSeriesDashboardOnline(start, end)
        }
    }

    async fetchSeriesDashboardOffline(start, end) {
        this.isLoading = true
        const q: any = {
            start: start,
            end: end
        }
        if(this.selCoursesVersion > 0)
            q.version = this.selCoursesVersion
        const res = await createRequest(`/dashboard/series/${this.seriesId}/offline`, 'get', q).send()

        this.courses = res.data.courses
        this.attendances = res.data.attendances
        this.retention = res.data.retention
        this.revenue = res.data.revenue
        this.retentionTable = res.data.retention_table

        this.allVolCat = _.map(this.courses, (item, idx) => {
            return 'Vol.' + (idx + 1) + ' ' + item._id
        })

        this.versionOptions = [{text: 'All versions', version: 0}]
        if (res.data.max_version > 1) {
            this.versionOptions.push(...Array.from({length: res.data.max_version}).map((_, i) => {
                const v = i + 1
                return {text: 'Version ' + v, version: v}
            }))
        }

        this.isLoading = false
    }

    async fetchSeriesDashboardOnline(start, end) {
        this.isLoading = true
        const res = await createRequest(`/dashboard/series/${this.seriesId}/online`, 'get', {
            start: start,
            end: end
        }).send()

        this.courses = res.data.courses || []
        this.avgProgress = res.data.avg_progress || []
        this.revenue = res.data.revenue || []

        this.allVolCat = _.map(this.courses, (item, idx) => {
            return 'Vol.' + (idx + 1) + ' ' + item._id
        })
        this.isLoading = false
    }


    async datePickerChanged() {
        this.realDateRange = [moment(this.selDateRange[0]).startOf('day').valueOf(), moment(this.selDateRange[1]).endOf('day').valueOf()]
    }

    @Watch('selSeriesType')
    async watchSelSeriesType(newVal, oldVal) {
        if (newVal !== oldVal) {
            await this.fetchSeriesDashboard()
        }
    }

    @Watch('realDateRange')
    async watchRealDateRange(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
            await this.fetchSeriesDashboard()
        }
    }

}
